/* eslint-disable @tanstack/query/exhaustive-deps -- doesn't work with the key factory*/
import { useQuery } from "@tanstack/react-query";

import { getAuth } from "~/providers/store/auth";
import { useDiningOption } from "~/providers/store/basket/hooks";
import { useEmployee } from "~/providers/store/employee/hooks";

import { useCurrentTill } from "../../tills/hooks/use-till";
import { productsKeys } from "../keys";
import { getProducts } from "../requests";

type UseProductsFilters = {
  groupName?: string | null;
  childId?: string | null;
  diningOption?: {
    inputId: string;
    value: string;
  } | null;
};

export function useProducts({
  groupName,
  childId,
  diningOption
}: UseProductsFilters) {
  const { data: till } = useCurrentTill();

  return useQuery({
    queryKey: productsKeys.list({
      tillId: till?.id ?? "unknown",
      user: {
        groupName: groupName ?? null,
        childId: childId ?? null
      },
      filters: {
        diningOption: diningOption?.value ?? null
      }
    }),
    queryFn: async () => {
      if (!till) {
        throw new Error("Till not found");
      }

      const auth = getAuth();

      return getProducts({
        groupName: groupName ?? undefined,
        childId: childId ?? undefined,
        diningOptionInputId: diningOption?.inputId ?? undefined,
        diningOptionValue: diningOption?.value ?? undefined,
        tillId: till.id,
        priceLocale: auth.locale ?? undefined,
        language: auth.language ?? undefined
      });
    }
  });
}

export function useProductsPOS() {
  const { employeeChildId, groupName } = useEmployee();

  const diningOption = useDiningOption();

  return useProducts({
    childId: employeeChildId,
    groupName,
    diningOption
  });
}
